<script setup>
import { useDisplay } from 'vuetify/lib/framework.mjs'

const authStore = useAuthStore()
const supabase = useSupabaseClient()
const { mdAndUp, platform } = useDisplay()

const user = await authStore.getCurrentUser()

const initials = computed(() => getInitials(user.name))

const layout = ref(null)

const state = reactive({
  drawer: false,
  userMenu: false,
})

const isPermanent = computed(() => {
  if (mdAndUp.value || platform.value.ssr)
    return true
  return false
})

const isShown = computed({
  get() {
    if (isPermanent.value)
      return true
    return state.drawer
  },
  set(value) {
    if (!value)
      state.drawer = false
  },
})

async function handleSignOut() {
  await supabase.auth.signOut()
  navigateTo('/login')
}

onMounted(() => setTimeout(() => layout.value.$el.classList.remove('hideMobileNav'), 500))
</script>

<template>
  <v-app>
    <v-layout
      ref="layout"
      class="hideMobileNav"
      full-height
    >
      <v-app-bar
        height="80"
        elevation="0"
        border
      >
        <NuxtLink to="/">
          <v-img
            aspect-ratio="2.481481481481481"
            alt="First Reformed Church"
            class="ml-4"
            src="/img/logo.png"
            width="160px"
            height="55px"
            contain
          />
        </NuxtLink>

        <v-spacer />

        <v-menu
          v-model="state.userMenu"
          location="bottom"
        >
          <template #activator="{ props }">
            <v-btn
              icon
              v-bind="props"
            >
              <v-avatar color="surface-variant">
                {{ initials }}
              </v-avatar>
            </v-btn>
          </template>

          <v-card min-width="300">
            <v-list>
              <v-list-item
                :title="user.name"
                subtitle="Logged In"
              >
                <template #prepend>
                  <v-avatar color="surface-variant">
                    {{ initials }}
                  </v-avatar>
                </template>
              </v-list-item>
            </v-list>

            <v-divider />

            <v-list>
              <v-list-item
                title="My Account"
                to="/members/my-account"
              />

              <v-list-item
                title="Log Out"
                @click="handleSignOut"
              />
            </v-list>
          </v-card>
        </v-menu>

        <v-app-bar-nav-icon
          v-if="!isPermanent"
          @click="state.drawer = !state.drawer"
        />
      </v-app-bar>
      <v-navigation-drawer
        v-model="isShown"
        :permanent="isPermanent"
        width="300"
      >
        <v-list
          density="compact"
          nav
        >
          <v-list-item
            prepend-icon="mdi-home"
            title="Home"
            to="/members"
          />

          <v-list-item
            prepend-icon="mdi-calendar"
            title="Calendar"
            to="/members/calendar"
          />

          <v-list-item
            prepend-icon="mdi-hand-coin"
            title="Thithing"
            to="/members/thithing"
          />
        </v-list>

        <template #append>
          <v-list nav>
            <v-list-subheader>ADMINISTRATION</v-list-subheader>
            <LayoutUsersLink v-if="user.roles.includes('UserManagement')" />
          </v-list>
        </template>
      </v-navigation-drawer>
      <v-main class="main">
        <div class="wrapper">
          <slot />
        </div>
      </v-main>
    </v-layout>

    <ConfirmDialog />
  </v-app>
</template>

<style lang="scss">
  @use 'vuetify/settings' as v;

  html {
    overflow: hidden !important;
  }
  
  .main {
    height: 100vh;
    display: flex;

    .wrapper {
      display: flex;
      flex-grow: 1;
      height: 100%;
      max-height: 100%;
      overflow: auto;
    }
  }

  @media #{map-get(v.$display-breakpoints, 'sm-and-down')} {
    .hideMobileNav {
      .v-main {
        --v-layout-left: 0px !important
      }

      .v-navigation-drawer,
      .v-navigation-drawer__scrim {
        display: none;
      }
    }
  }
</style>
