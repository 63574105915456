<script setup>
const supabase = useSupabaseClient()

const state = reactive({
  usersNotification: false,
})

async function checkUsersRequireReview() {
  const { count } = await supabase
    .from('Users')
    .select('*', { count: 'estimated', head: true })
    .eq('requires_review', true)

  state.usersNotification = count
}
checkUsersRequireReview()
</script>

<template>
  <v-list-item
    prepend-icon="mdi-account-group-outline"
    title="Users"
    to="/members/users"
  >
    <template
      v-if="state.usersNotification > 0"
      #append
    >
      <v-badge
        color="error"
        :content="state.usersNotification"
        max="9"
        inline
      />
    </template>
  </v-list-item>
</template>
